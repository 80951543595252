<template>
	<theoric-frame></theoric-frame>
</template>

<script>
import TheoricFrame from '@/components/TheoricFrame';

export default {
	name: 'Home',

	components: { TheoricFrame },
};
</script>
